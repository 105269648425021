import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { normalizeDomain, validateDomain } from "../../utils/registration";

interface PreviewFormProps {
  region: string;
  translations: any;
}

const PreviewForm: React.FC<PreviewFormProps> = ({
  region = "SE",
  translations,
}) => {
  const [isPreviewSubmitted, setIsPreviewSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Ogiltig e-postadress")
      .required("E-postadress krävs"),
    domain: Yup.string()
      .test(
        "isValidDomain",
        `${translations.registration.mainForm.validationSchema.domain.invalid}`,
        (value) => validateDomain(value || "")
      )
      .required(`${translations.registration.mainForm.validationSchema.domain.required}`)
  });

  const formik = useFormik({
    initialValues: { 
      domain: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const domain = normalizeDomain(values.domain as string) ?? "";
        const response = await fetch("/bff/preview-widget", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            domain: domain,
            campaignId: "leadcaller-homepage",
            region: region,
            email: values.email
          }),
        });

        const result = await response.json();
        console.log(result);

        if (result.success) {
          setIsPreviewSubmitted(true);

          setTimeout(() => {
            const newWindowUrl = `https://admin.leadcaller.com/preview-widget?dId=${domain}&cId=leadcaller-homepage`;
            window.open(newWindowUrl, "_blank");
            setIsPreviewSubmitted(false);
          }, 3000);
        } else {
          console.error("Error:", result);
          setErrorMessage(result.error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  if (isPreviewSubmitted) {
    return (
      <div className="px-4 flex flex-col justify-center items-center w-full bg-pureWhite text-center">
        <div className="flex flex-col items-center justify-center p-4 lg:p-8">
          <div className="flex flex-row w-full text-center items-center justify-center">
            <span className="text-base lg:text-4xl max-w-[900px] text-deepPurple font-bold mt-4 lg:mt-10 mb-1 lg:mb-4">
              {translations.previewForm.generatingWidget}
            </span>
            <div className="ml-2 lg:ml-8 border-white h-5 w-5 lg:h-7 lg:w-7 animate-spin rounded-full border-8 border-t-deepPurple" />
          </div>
          <span className="mt-2 lg:mt-4 text-xxs lg:text-xs">
            {translations.previewForm.reDirect}
          </span>
        </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="h-auto w-full text-deepPurple"
    >
      <div className="m-[30px]">
        <div className="flex flex-col mt-2 lg:mt-3">
          <label
            htmlFor="email"
            className="flex flex-row text-deepPurple items-start gap-x-2 text-[14px] leading-[22px] lg:text-[18px] lg:leading-[24px] font-bold"
          >
            {translations.registration.mainForm.placeholders.email}{" "}
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.email}
              </div>
            ) : null}
          </label>
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={`bg-transparent mt-1 lg:mt-2 text-xs lg:text-base h-[36px] lg:h-[60px] w-full
              lg:min-w-[400px] lg:max-w-[600px] px-2 py-2.5 lg:px-4 lg:py-5 text-deepPurple border
              ${
                formik.touched.email && formik.errors.email
                  ? "border-red-500"
                  : "border-deepPurple"
              } rounded-[10px] lg:rounded-[15px] mr-1 lg:mr-4 focus:outline-none focus:ring-2
              ${
                formik.touched.email && formik.errors.email
                  ? "focus:ring-red-500"
                  : "focus:ring-deepPurple"
              }`}
            placeholder={translations.registration.mainForm.placeholders.email}
            aria-label="email address"
            title="email address"
          />
        </div>
        <div className="flex flex-col mt-4 lg:mt-6">
          <label
            htmlFor="domain"
            className="flex flex-row text-deepPurple items-start gap-x-2 text-[14px] leading-[22px] lg:text-[18px] lg:leading-[24px] font-bold"
          >
          {translations.registration.mainForm.placeholders.domain}{" "}
          {formik.touched.domain && formik.errors.domain ? (
            <div className="text-red-500 text-sm mb-2 text-start">
              {formik.errors.domain}
            </div>
          ) : null}
        </label>
        <input 
          type="text" 
          name="domain"
          onFocus={() => {
            setErrorMessage(null);
            if (formik.values.domain === "") {
              formik.setFieldValue('domain', "https://");
            } else if (!formik.values.domain.startsWith('http://') && !formik.values.domain.startsWith('https://')) {
              formik.setFieldValue('domain', `https://${formik.values.domain}`);
            }
          }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.domain}
          className={`bg-transparent mt-1 lg:mt-2 text-xs lg:text-base h-[36px] lg:h-[60px] w-full
            lg:min-w-[400px] lg:max-w-[600px] px-2 py-2.5 lg:px-4 lg:py-5 text-deepPurple border
            ${
              errorMessage !== null || (formik.touched.domain && formik.errors.domain) 
              ? "border-red-500"
              : "border-deepPurple"
            } rounded-[10px] lg:rounded-[15px] mr-1 lg:mr-4 focus:outline-none focus:ring-2
            ${
              errorMessage !== null || (formik.touched.domain && formik.errors.domain)
              ? "focus:ring-red-500"
              : "focus:ring-deepPurple"
            }`}
          placeholder="URL" 
          aria-label="Website address" 
          title="Preview Widget"
          required
        />
        </div>
        <div className="flex text-start items-center my-2 lg:my-4 col-span-1">
          <p className="block text-xxs lg:text-[12px] max-w-[600px] text-deepPurple font-semibold mb-1 lg:mb-2">
            {"*"}{translations.previewForm.disclaimer}
          </p>
        </div>
        <div className="items-center">
          <button 
            id="preview-URL-btn"
            type="submit"
            className={`bg-purpleAccent text-pureWhite w-full h-9 lg:h-12 rounded-[10px]
              lg:rounded-xl px-6 lg:px-[30px] py-2.5 lg:py-3 text-xs lg:text-base text-nowrap font-bold
              hover:bg-lightPurple hover:text-pureWhite hover:border-transparent transition duration-300
              ease-in-out z-20 whitespace-normal overflow-hidden`}
          >
            {translations.previewForm.cta}
          </button>
        </div>
      </div>
    </form>
  );
};

export default PreviewForm;
