import { parse } from "tldts";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export function normalizeDomain(domain: string): string | null {
  // Remove protocol if present
  domain = domain.replace(/(^\w+:|^)\/\//, "");

  // Remove 'www.' if present
  domain = domain.replace(/^www\./, "");

  const parsedDomain = parse(domain);

  if (parsedDomain.domain && parsedDomain.isIcann) {
    return parsedDomain.hostname;
  }

  return null;
}

export function validateDomain(domain: string): boolean {
  // Remove protocol if present
  domain = domain.replace(/(^\w+:|^)\/\//, "");

  // Remove 'www.' if present
  domain = domain.replace(/^www\./, "");

  const parsedDomain = parse(domain);
  return !!(parsedDomain.domain && parsedDomain.isIcann);
}

const useSessionStorage = (key: string, initialValue: any) => {
  const [storedValue, setStoredValue] = useState<any>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.error(error);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue] as const;
};

export const useSessionData = () => {
  const [sessionData, setSessionData] = useSessionStorage("sessionData", {
    userId: uuidv4(),
    companyId: uuidv4(),
  });

  useEffect(() => {
    if (!sessionData.userId) {
      setSessionData((prev: any) => ({ ...prev, userId: uuidv4() }));
    }
    if (!sessionData.companyId) {
      setSessionData((prev: any) => ({ ...prev, companyId: uuidv4() }));
    }
  }, [sessionData, setSessionData]);

  return sessionData;
};

const orgNumberValidators: {
  [key: string]: { regex: RegExp; formats: string[] };
} = {
  DE: { regex: /^[1-9][0-9]{8}$/, formats: ["xxxxxxxxx"] },
  DK: { regex: /^[1-9][0-9]{7}$/, formats: ["xxxxxxx"] },
  ES: { regex: /^[A-Z][0-9]{7}[A-Z0-9]$/, formats: ["x9999999x"] },
  FI: { regex: /^[0-9]{7}-?[0-9]$/, formats: ["9999999-9"] },
  FR: { regex: /^[0-9A-Z]{2}[0-9]{9}$/, formats: ["XX999999999"] },
  GB: {
    regex: /^[0-9]{8}$|^[A-Z]{2}[0-9]{6}$/,
    formats: ["99999999", "XX999999"],
  },
  IE: { regex: /^[0-9A-Z]{7}[A-Z]{1}$/, formats: ["XXXXXXXx"] },
  IT: { regex: /^[0-9]{11}$/, formats: ["99999999999"] },
  NO: { regex: /^[0-9]{9}$/, formats: ["999999999"] },
  SE: { regex: /^[0-9]{6}-?[0-9]{4}$/, formats: ["999999-9999"] },
  US: {
    regex: /^[1-9][0-9]?-?[0-9]{7}$/,
    formats: ["99-9999999", "999999999"],
  },
};

export const validateOrgNum = (
  orgNum: string,
  countryCode: string
): boolean => {
  const validator = orgNumberValidators[countryCode.toUpperCase()];
  return validator ? validator.regex.test(orgNum) : false;
};

export const getFormatErrorMessage = (
  countryCode: string,
  translations: any
) => {
  const formats =
    orgNumberValidators[countryCode.toUpperCase()]?.formats.join("| ");
  return `${translations.mainForm.validationSchema.orgNum.invalid} | ${formats}`;
};
